import React from 'react';
import { Tooltip } from 'flex';
import { BsFillGridFill } from 'react-icons/bs';
import { RxHamburgerMenu } from 'react-icons/rx';

export function SwitchViewButtons({
  iconLeftButton,
  iconRightButton,
  handleLeftClick,
  handleRightClick,
  textLeftToolTip,
  textRightToolTip,
  isLeftButtonSelected,
}: {
  iconLeftButton?: React.ReactNode;
  iconRightButton?: React.ReactNode;
  handleLeftClick: () => void;
  handleRightClick: () => void;
  textLeftToolTip: string;
  textRightToolTip: string;
  isLeftButtonSelected: boolean;
}) {
  return (
    <div className="flex">
      <Tooltip
        trigger={
          <button
            type="button"
            onClick={handleLeftClick}
            className={`cursor-pointer rounded-l-md border border-solid border-slate-300 p-3 ${
              isLeftButtonSelected ? 'bg-slate-100' : ''
            }`}
          >
            {iconLeftButton ? iconLeftButton : <BsFillGridFill />}
          </button>
        }
      >
        {textLeftToolTip}
      </Tooltip>
      <Tooltip
        trigger={
          <button
            type="button"
            onClick={handleRightClick}
            className={`cursor-pointer rounded-r-md border border-solid border-slate-300 p-3 ${
              !isLeftButtonSelected ? 'bg-slate-100' : ''
            }`}
          >
            {iconRightButton ? iconRightButton : <RxHamburgerMenu />}
          </button>
        }
      >
        {textRightToolTip}
      </Tooltip>
    </div>
  );
}
