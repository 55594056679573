import React from 'react';
import { Text, Heading } from 'flex';

import { Column, TableProps, SortDirection, SortField } from './table.type';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';

export const Table = <T,>({
  columns,
  data,
  onRowClick,
  onHeaderSortClick,
  sortField,
}: TableProps<T>) => {
  return (
    <table className="w-full table-fixed border-collapse">
      <TableHeader<T>
        columns={columns}
        onHeaderSortClick={onHeaderSortClick}
        sortField={sortField}
      />
      <TableBody<T> columns={columns} data={data} onRowClick={onRowClick} />
    </table>
  );
};

function TableHeader<T>({
  columns,
  onHeaderSortClick,
  sortField,
}: {
  columns: Column<T>[];
  onHeaderSortClick?: (sortField: SortField<T>) => void;
  sortField?: SortField<T>;
}) {
  return (
    <thead className="bg-white">
      <tr>
        {columns.map((column, index) => (
          <th
            key={String(column.key)}
            className={`text-xs font-medium tracking-wider ${
              index === 0 ? 'text-left' : 'text-center'
            } ${column.responsiveWitdh || ''}
            }`}
          >
            <div
              className={`flex items-center gap-2 border-b-2 border-solid border-slate-200 pb-4 uppercase ${
                index === 0 ? '' : index === columns.length - 1 ? 'justify-end' : 'justify-center'
              }`}
            >
              {column.useForSort ? (
                <button
                  className="flex items-center gap-2 focus:outline-none"
                  onClick={() => {
                    if (onHeaderSortClick) {
                      const newSortOrder =
                        sortField?.field === column.key &&
                        sortField.typeOfSort === SortDirection.ASC
                          ? SortDirection.DESC
                          : SortDirection.ASC;

                      onHeaderSortClick({ field: column.key, typeOfSort: newSortOrder });
                    }
                  }}
                >
                  {typeof column.name === 'string' ? (
                    <Heading level={0} className="text-sm uppercase">
                      {column.name}
                    </Heading>
                  ) : (
                    column.name()
                  )}
                  {sortField?.field === column.key ? (
                    sortField.typeOfSort === SortDirection.ASC ? (
                      <ArrowUpIcon className="h-4 w-4 text-blue-500" />
                    ) : (
                      <ArrowDownIcon className="h-4 w-4 text-blue-500" />
                    )
                  ) : (
                    <ArrowDownIcon className="h-3 w-3 opacity-50" />
                  )}
                </button>
              ) : (
                <>
                  {typeof column.name === 'string' ? (
                    <Heading level={0} className="text-sm uppercase">
                      {column.name}
                    </Heading>
                  ) : (
                    column.name()
                  )}
                </>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}

function TableBody<T>({ columns, data, onRowClick }: TableProps<T>) {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {data.map((item, rowIndex) => (
        <tr
          key={rowIndex}
          className={`h-full cursor-pointer hover:bg-slate-50 hover:shadow-xl`}
          onClick={() => onRowClick && onRowClick(item)}
        >
          {columns.map((column, index) => (
            <td
              key={String(column.key)}
              className={`h-full align-middle text-sm ${
                index === 0
                  ? 'text-left'
                  : index === columns.length - 1
                  ? 'text-right'
                  : 'text-center'
              }
              ${column.responsiveWitdh || ''}
              ${rowIndex !== data.length - 1 ? 'border-b-2 border-solid border-slate-200' : ''}
              `}
            >
              {column.renderCell ? (
                column.renderCell(item)
              ) : (
                <Text className="flex h-20 w-full items-center justify-center">
                  {item[column.key]}
                </Text>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
