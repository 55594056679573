/* eslint-disable @typescript-eslint/no-explicit-any */
// Path: packages/flex/src/Select/Select.tsx

import React, { cloneElement, Dispatch, SetStateAction, useMemo } from 'react';
import PrimitiveSelect, {
  components,
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  IndicatorSeparatorProps,
  MenuListProps,
  MenuProps,
  NoticeProps,
  OptionProps,
  Props as Properties,
  ValueContainerProps,
} from 'react-select';
import clsx from 'clsx';
import useMeasure, { RectReadOnly } from 'react-use-measure';
import { motion } from 'framer-motion';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Text } from '../Text';
import type { SelectProperties } from './select.types';
import { Avatar } from '../Avatar/avatar';

// wrapper for react-select that allows extra props on the options and groups
// and adds a few extra features
function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(properties: Properties<Option, IsMulti, Group>) {
  return <PrimitiveSelect {...properties} />;
}

const defaultTranslationKeys = {
  noOptionsMessage: 'No options',
  placeholder: 'Select...',
  moreValues: 'and {count} more',
};

export type TSelectTranslationKeys = Record<keyof typeof defaultTranslationKeys, string>;

type TStatusDot = 'active' | 'inactive' | 'warning' | 'error';
// TODO: optimize to a forest of color strings, and not components
const StatusDot: Record<TStatusDot, () => JSX.Element> = {
  active: () => <span className="h-2 w-2 flex-shrink-0 rounded-full bg-emerald-400" />,
  inactive: () => <span className="h-2 w-2 flex-shrink-0 rounded-full bg-slate-400" />,
  warning: () => <span className="h-2 w-2 flex-shrink-0 rounded-full bg-amber-400" />,
  error: () => <span className="h-2 w-2 flex-shrink-0 rounded-full bg-rose-400" />,
};

const CustomComponents = {
  ValueContainer:
    (t: TSelectTranslationKeys) =>
    ({ children, ...properties }: ValueContainerProps<any>) => {
      const [values, input] = children as any; // TODO: Improve this type

      if (values.key === 'placeholder') {
        return (
          <components.ValueContainer {...properties}>
            <Text type="muted" className="text-left">
              {values.props.children}
            </Text>

            {cloneElement(input, {
              className: 'sr-only',
            })}
          </components.ValueContainer>
        );
      }

      if (values.key !== 'placeholder' && values.props?.hasValue) {
        return (
          <div className="flex items-center gap-2 overflow-auto px-2">
            {values.props.data.statusDot && StatusDot[values.props.data.statusDot as TStatusDot]()}
            {values.props.data.avatar && (
              <Avatar
                {...values.props.data.avatar}
                key={values?.props.label ?? values?.props.children}
              />
            )}
            <Text className="inline-flex space-x-1 overflow-auto truncate">
              <Text as="span" className="flex-shrink-0 truncate">
                {values?.props.label ?? values?.props.children}
              </Text>
              {values.length > 1 ? (
                <Text as="span" type="muted" className="whitespace-nowrap">
                  {t.moreValues.replace('{count}', `${values.length - 1}`)}
                </Text>
              ) : (
                ''
              )}
              {values.props.data.secondaryLabel && values.length === 1 && (
                <Text as="span" type="muted" className="whitespace-nowrap">
                  {values.props.data.secondaryLabel}
                </Text>
              )}
            </Text>
          </div>
        );
      }

      return (
        <components.ValueContainer {...properties}>
          {values.length > 0 && (
            <div className="flex items-center gap-2 overflow-auto">
              {values[0].props.data.statusDot &&
                StatusDot[values[0].props.data.statusDot as TStatusDot]()}
              {values[0].props.data.avatar && (
                <Avatar
                  {...values[0].props.data.avatar}
                  key={values[0]?.props.label ?? values[0]?.props.children}
                />
              )}
              <Text className="inline-flex space-x-1 overflow-auto">
                <Text as="span" className="flex-shrink-0 truncate">
                  {values[0]?.props.label ?? values[0]?.props.children}
                </Text>
                {values.length > 1 ? (
                  <Text as="span" type="muted" className="whitespace-nowrap">
                    {t.moreValues.replace('{count}', `${values.length - 1}`)}
                  </Text>
                ) : (
                  ''
                )}
                {values[0].props.data.secondaryLabel && values.length === 1 && (
                  <Text as="span" type="muted" className="whitespace-nowrap">
                    {values[0].props.data.secondaryLabel}
                  </Text>
                )}
              </Text>
            </div>
          )}

          {cloneElement(input, {
            className: 'sr-only',
          })}
        </components.ValueContainer>
      );
    },
  DropdownIndicator: (properties: DropdownIndicatorProps<any>) => (
    <components.DropdownIndicator {...properties}>
      <ChevronUpDownIcon className="h-4 w-4 text-slate-600" />
    </components.DropdownIndicator>
  ),
  GroupHeading: (properties: GroupHeadingProps<any>) => (
    <components.GroupHeading {...properties}>
      <Text brow className="!text-slate-600">
        {properties.children?.toString() ?? ''}
      </Text>
    </components.GroupHeading>
  ),
  SelectContainer:
    (
      reference: HTMLOrSVGElement | null,
      fullWidth: boolean,
      name: string,
      label?: string,
      size?: string,
    ) =>
    (properties: ContainerProps<any>) => {
      return (
        <div ref={reference as never}>
          <components.SelectContainer
            {...properties}
            isDisabled={properties.isDisabled}
            className={clsx(
              'space-y-1',
              fullWidth && 'w-full',
              size || 'w-64',
              properties.isDisabled && 'cursor-not-allowed opacity-60',
            )}
          >
            {label && (
              <label aria-label={label} htmlFor={name}>
                <Text as="span" type="secondary" strong="medium">
                  {label}
                </Text>
              </label>
            )}
            {properties.children}
          </components.SelectContainer>
        </div>
      );
    },
  Menu:
    (
      reference: HTMLOrSVGElement | null,
      fullWidth: boolean,
      controlBounds: RectReadOnly,
      menuBounds: RectReadOnly,
    ) =>
    (properties: MenuProps<any>) => {
      return (
        <components.Menu {...properties} className="!shadow-none">
          <motion.div
            ref={reference as never}
            className={clsx(
              !fullWidth && 'w-64',
              'fixed z-50 mt-2 max-w-[calc(100vw-2rem)] rounded-md border border-solid border-slate-200 bg-white shadow-md',
            )}
            style={{
              // make sure the tooltip doesn't go off the bottom of the screen
              // if it does, move it above the select
              top:
                controlBounds.top + controlBounds.height + menuBounds.height > window?.innerHeight
                  ? controlBounds.top - menuBounds.height
                  : controlBounds.top + controlBounds.height,
              left: controlBounds.left,

              // make sure the tooltip doesn't go off the right of the screen
              // if it does, move it to the left
              right:
                controlBounds.left + menuBounds.width > window?.innerWidth
                  ? window?.innerWidth - menuBounds.width
                  : undefined,

              width: fullWidth ? controlBounds.width : undefined,
            }}
            initial={{ opacity: 0, y: -2 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -2 }}
            transition={{ duration: 0.2 }}
            {...properties}
          >
            {properties.children}
          </motion.div>
        </components.Menu>
      );
    },
  Control:
    (
      disabled: boolean,
      setIsOpen: Dispatch<SetStateAction<boolean>>,
      isOpen: boolean,
      error: boolean,
    ) =>
    (properties: ControlProps<any>) =>
      (
        <button
          onClick={() => {
            if (!disabled) setIsOpen((isOpen) => !isOpen);
          }}
          className={clsx(
            'inline-flex w-full rounded-md border border-solid bg-white transition-all hover:bg-slate-50',
            error ? 'border-rose-300' : 'border-slate-300',
            isOpen
              ? clsx(
                  'outline-none ring-2  ring-offset-2 ring-offset-slate-50',
                  error ? 'ring-rose-500' : 'ring-primary-500',
                )
              : 'ring-0',
          )}
        >
          <components.Control
            {...properties}
            className="w-full flex-shrink-0 !flex-nowrap !border-none !bg-transparent !shadow-none"
          >
            {properties.children}
          </components.Control>
        </button>
      ),
  Option: (properties: OptionProps<any>) => {
    return (
      <components.Option
        {...properties}
        data={properties.data}
        isSelected={properties.isSelected}
        isDisabled={properties.isDisabled ?? false}
        className={clsx(
          properties.data.disabled
            ? '!bg-slate-200 hover:cursor-not-allowed'
            : '!bg-white hover:!bg-slate-100',
        )}
      >
        <div className="flex items-center justify-between gap-2 overflow-auto">
          <div className="flex items-center gap-2 overflow-auto">
            {properties.data.statusDot && StatusDot[properties.data.statusDot as TStatusDot]()}
            {properties.data.avatar && (
              <Avatar
                {...properties.data.avatar}
                key={properties.children?.toString() ?? properties.data.label}
              />
            )}
            <Text
              className="inline-flex space-x-1 overflow-auto truncate"
              disabled={properties.data.disabled}
              title={`${properties.data.label}${
                properties.data.secondaryLabel ? `, ${properties.data.secondaryLabel}` : ''
              }`}
            >
              <Text
                as="span"
                className="flex-shrink-0 truncate"
                strong={properties.isSelected ? 'semi' : undefined}
              >
                {properties.children ?? properties.data.label}
              </Text>
              {properties.data.secondaryLabel && (
                <Text as="span" type="muted" className="truncate whitespace-nowrap">
                  {properties.data.secondaryLabel}
                </Text>
              )}
            </Text>
          </div>

          {properties.isSelected && <CheckIcon className="h-4 w-4 text-slate-900" />}
        </div>
      </components.Option>
    );
  },
  MenuList: (properties: MenuListProps<any>) => (
    <components.MenuList {...properties} className="rounded-md !py-0">
      {properties.children}
    </components.MenuList>
  ),
  NoOptionsMessage: (t: TSelectTranslationKeys) => (properties: NoticeProps<any>) =>
    (
      <components.NoOptionsMessage {...properties} className="!py-0">
        <Text type="muted" className="!py-2">
          {t.noOptionsMessage}
        </Text>
      </components.NoOptionsMessage>
    ),

  IndicatorSeparator: ({ innerProps }: IndicatorSeparatorProps<any>) => {
    return (
      <span {...innerProps} className="h-3/5 border-l border-slate-400 [border-left-style:solid]" />
    );
  },
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use `SearchSelect` instead.
 */
export const Select = ({
  className,
  onValueChange,
  label,
  size = 'w-64',
  defaultValue,
  loading,
  name,
  disabled = false,
  clearable = false,
  multiple,
  fullWidth = false,
  required,
  options = [],
  translations,
  onMenuScrollToBottom,
  value,
  error,
}: SelectProperties): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [controlReference, controlBounds] = useMeasure();
  const [menuReference, menuBounds] = useMeasure();

  // escape key event listener
  React.useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') setIsOpen(false);
    };

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  // merge the default translations with the user's translations if there are any
  const t: typeof defaultTranslationKeys = useMemo(
    () => ({
      ...defaultTranslationKeys,
      ...translations,
    }),
    [translations],
  );

  return (
    <CustomSelect
      {...(value ? { value } : {})}
      className={className}
      placeholder={t.placeholder}
      name={name}
      isDisabled={disabled}
      isSearchable={false} // TODO: add search functionality
      isClearable={clearable}
      defaultValue={defaultValue}
      isMulti={multiple}
      required={required}
      menuIsOpen={isOpen}
      options={options}
      onChange={(value) => onValueChange?.(value)}
      isOptionDisabled={(option) => option.disabled ?? false}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      styles={{}}
      menuPortalTarget={typeof document !== 'undefined' ? document.body : undefined}
      menuShouldBlockScroll
      menuShouldScrollIntoView
      onBlur={() => setIsOpen(false)}
      closeMenuOnSelect={!multiple}
      isLoading={loading}
      hideSelectedOptions={false}
      components={{
        ValueContainer: CustomComponents.ValueContainer(t),
        DropdownIndicator: CustomComponents.DropdownIndicator,
        GroupHeading: CustomComponents.GroupHeading,
        SelectContainer: CustomComponents.SelectContainer(
          controlReference as never,
          fullWidth,
          name,
          label,
          size,
        ),
        Menu: CustomComponents.Menu(menuReference as never, fullWidth, controlBounds, menuBounds),
        Control: CustomComponents.Control(disabled, setIsOpen, isOpen, !!error),
        Option: CustomComponents.Option,
        MenuList: CustomComponents.MenuList,
        NoOptionsMessage: CustomComponents.NoOptionsMessage(t),
        IndicatorSeparator: CustomComponents.IndicatorSeparator,
      }}
      onMenuScrollToBottom={onMenuScrollToBottom}
    />
  );
};
