import React from 'react';
import clsx from 'clsx';
import { HeadingProperties } from './heading.types';

const sizeVariants: Record<Exclude<HeadingProperties['level'], undefined>, string> = {
  0: '',
  1: 'text-5xl',
  2: 'text-4xl',
  3: 'text-3xl',
  4: 'text-2xl',
  5: 'text-xl',
  6: 'text-lg',
  7: 'text-base',
  8: 'text-sm',
};

/**
 *
 * @group Components
 */
export const Heading = ({
  children,
  level = 1,
  className,
  style,
  title,
  color = 'text-slate-900',
}: HeadingProperties): JSX.Element => {
  const Component = React.createElement(
    level === 0 ? 'p' : `h${level}`,
    {
      className: clsx(sizeVariants[level], className, color, ' font-title font-medium'),
      role: 'heading',
      style,
      title: title || children,
    },
    children,
  );

  return Component;
};
