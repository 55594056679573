import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import Tippy from '@tippyjs/react';
import Text from 'components/Text';
import addBindTo from '../Provider/hocs/addBindTo';

const Tooltip = ({
  className,
  text,
  position,
  children,
  positionModifier,
  visible,
  duration,
  tooltipTheme,
  interactive,
  disabled,
  willBeTruncated = false,
}) => {
  const contentRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const checkTruncation = () => {
    if (contentRef.current) {
      const element = contentRef.current;
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  };

  useEffect(() => {
    checkTruncation();

    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [text, children]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const shouldShowTooltip = willBeTruncated
    ? isTruncated && isHovered && !disabled && text
    : isHovered && !disabled && text;

  return (
    <Tippy
      content={<Text className="whitespace-pre-line break-words font-secondary">{text}</Text>}
      placement={`${position}${positionModifier === 'start' ? '-start' : ''}${
        positionModifier === 'end' ? '-end' : ''
      }`}
      duration={duration}
      visible={visible ?? shouldShowTooltip}
      theme={tooltipTheme}
      interactive={interactive}
      disabled={disabled || !text || (typeof text === 'string' && !text.trim())}
    >
      <div
        className={willBeTruncated ? 'w-full truncate' : ''}
        ref={contentRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {React.isValidElement(children) ? children : <span className={className}>{children}</span>}
      </div>
    </Tippy>
  );
};

Tooltip.defaultProps = {
  className: '',
  theme: { colors: defaultColors },
  positionModifier: '',
  visible: undefined,
  duration: 100,
  tooltipTheme: 'default',
  interactive: false,
  children: null,
  position: 'top',
  disabled: false,
  willBeTruncated: false,
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  visible: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  tooltipTheme: PropTypes.oneOf(['default', 'light']),
  duration: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  positionModifier: PropTypes.oneOf(['end', 'start', '']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  willBeTruncated: PropTypes.bool,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Tooltip);
