import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { colors as defaultColors } from 'tokens';
import formatDate from 'utils/formatDate';
import { withTranslation } from 'i18n';
import clsx from 'clsx';
import { BsXLg } from 'react-icons/bs';
import addBindTo from '../Provider/hocs/addBindTo';
import Button from '../Button/Button';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { SelectWrapper, SelectOptionWrapper } from './SelectWrapper';

const YESTERDAY = 1;
const WEEK = 7;
const LAST_THIRTY_DAYS = 30;

export const MOMENT_FORMAT = 'MM-DD-YYYY';

export function getDateRange(range) {
  const calculatedDate = moment().subtract(range, 'day').format(MOMENT_FORMAT);
  const today = moment().format(MOMENT_FORMAT);
  const yesterday = moment().subtract(YESTERDAY, 'day').format(MOMENT_FORMAT);
  const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format(MOMENT_FORMAT);
  const endOfLastMonth = moment().subtract(1, 'month').endOf('month').format(MOMENT_FORMAT);
  const startOfThisMonth = moment().startOf('month').format(MOMENT_FORMAT);
  const startOfThisYear = moment().startOf('year').format(MOMENT_FORMAT);

  return {
    calculatedDate,
    yesterday,
    startOfLastMonth,
    endOfLastMonth,
    startOfThisMonth,
    today,
    startOfThisYear,
  };
}

const SelectOption = ({
  className,
  option,
  setSelectedValue,
  setSelectedDate,
  onChange,
  theme,
  setDateRange,
  setStartDate,
  setEndDate,
  setIsCustomSelected,
  setOpenDatePicker,
  setOpen,
  appearance,
}) => {
  // eslint-disable-next-line consistent-return
  const handleClick = useCallback(() => {
    setSelectedValue(option.value);
    onChange(option.value);
    setOpen(false);

    if (appearance === 'date') {
      const {
        calculatedDate,
        yesterday,
        startOfLastMonth,
        endOfLastMonth,
        startOfThisMonth,
        today,
        startOfThisYear,
      } = getDateRange(option.value);
      setSelectedDate(option.value);
      setIsCustomSelected(false);
      setOpenDatePicker(false);
      setStartDate();
      setEndDate();

      if (option.value === YESTERDAY) return setDateRange({ start: yesterday, end: yesterday });
      if (option.value === WEEK) return setDateRange({ start: calculatedDate, end: yesterday });
      if (option.value === LAST_THIRTY_DAYS) {
        return setDateRange({ start: calculatedDate, end: yesterday });
      }

      if (option.value === 'lastMonth') {
        return setDateRange({ start: startOfLastMonth, end: endOfLastMonth });
      }

      if (option.value === 'thisMonth') {
        return setDateRange({ start: startOfThisMonth, end: today });
      }

      if (option.value === 'thisYear') return setDateRange({ start: startOfThisYear, end: today });
      if (option.value === 'all') return setDateRange({ start: null, end: today });
      if (option.value === 'none') return setDateRange({ start: null, end: null });

      setDateRange({ start: calculatedDate, end: today });
    }
  }, [option.value]);

  const filetypeRegex = new RegExp('^.*.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF|png|PNG)$');

  return (
    <SelectOptionWrapper className={`group ${className}`} onClick={handleClick} theme={theme}>
      {option.icon && filetypeRegex.test(option.icon) && (
        <img className="option__icon" alt={option.label} src={option.icon} />
      )}

      {option.icon && !filetypeRegex.test(option.icon) && (
        <i className={`option__icon ${option.icon}`} />
      )}

      <div data-cy={`select-option-${option.value}`}>
        {option.displayObject || option.label}
        {option.count !== undefined && option.count !== null && (
          <span className="option__count">({option.count})</span>
        )}
      </div>
    </SelectOptionWrapper>
  );
};

SelectOption.propTypes = {
  className: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setIsCustomSelected: PropTypes.func.isRequired,
  setOpenDatePicker: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  appearance: PropTypes.string.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ]).isRequired,
    icon: PropTypes.string,
    count: PropTypes.number,
    displayObject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }).isRequired,
};

export const Select = ({
  className,
  inputClassName,
  options,
  customLabel,
  value,
  appearance,
  disabled,
  onChange,
  top,
  theme,
  error,
  defaultLabel,
  dateRange,
  dateRangePickerPosition,
  setDateRange,
  maximumDate,
  minimumDate,
  selectedDate,
  setSelectedDate,
  isCustomSelected,
  setIsCustomSelected,
  clearable,
  label,
  lang,
  t,
  dropdownStyle,
  overrideOverflow,
}) => {
  moment.locale(lang);
  const dropdown = useRef();
  const [selectedValue, setSelectedValue] = useState();
  const [open, setOpen] = useState(false);
  const defaultOption = options.find((option) => option.value === value);
  const [startDate, setStartDate] = useState(
    isCustomSelected && dateRange.start ? moment(dateRange.start).toDate() : null,
  );
  const [endDate, setEndDate] = useState(
    isCustomSelected && dateRange.end ? moment(dateRange.end).toDate() : null,
  );

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const selectedOption =
    options.find((option) => option.value === selectedValue || option.value === selectedDate) ||
    defaultOption;
  const listRef = useRef(null);

  useEffect(() => {
    if (value !== selectedValue) setSelectedValue(value);
  }, [value, selectedValue]);

  function defaultValue() {
    if (isCustomSelected && appearance !== 'date') return customLabel;
    if (appearance === 'lang') return selectedOption?.value || defaultLabel;

    /* eslint-disable */
    if (appearance === 'date')
      return isCustomSelected && startDate && endDate
        ? t(`custom-dates-label`, {
            startDate: formatDate(startDate, lang),
            endDate: formatDate(endDate, lang),
          })
        : selectedOption?.label || 'Sélectionner une plage de dates';
    /* eslint-enable */

    if (appearance === 'sort') {
      return (selectedOption && selectedOption.label) || value || 'Trier par';
    }

    return (
      (selectedOption && selectedOption.label) ||
      value?.label ||
      value ||
      defaultLabel ||
      'Sélectionnez une option'
    );
  }

  function displayRange(range) {
    if (!range.start && !range.end) return 'Aucune plage de dates sélectionnée';

    const formatStart = formatDate(range.start);
    const formatEnd = formatDate(range.end);

    if (!range.start) return `Jusqu'au ${formatEnd}`;

    return `${formatStart} - ${formatEnd}`;
  }

  function displayedIcon(direction) {
    if (disabled) return null;
    if (value && clearable) {
      return (
        <BsXLg
          className="w-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(null);
          }}
        />
      );
    }

    if (appearance === 'sort') return `icon-arrow-ios-${direction}`;

    return `icon-arrow-drop-${direction}-fill`;
  }

  const handleClick = (e) => {
    if (dropdown.current.contains(e.target)) {
      return;
    }

    setOpenDatePicker(false);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <SelectWrapper
      className={`select ${className} w-full`}
      overrideOverflow={overrideOverflow}
      open={open}
      disabled={disabled}
      top={top}
      length={options.length}
      appearance={appearance}
      displayedIcon={
        (selectedOption && selectedOption.icon) || (defaultOption && defaultOption.icon)
      }
      theme={theme}
      isCustomSelected={isCustomSelected}
      dateRangePickerPosition={dateRangePickerPosition}
      error={error}
    >
      {label && <p className="select--label">{label}</p>}
      <div
        ref={dropdown}
        className="select--input"
        data-cy={selectedOption && 'select-default-option'}
      >
        <Button
          className="select__input"
          onClick={!disabled ? () => setOpen(!open) : undefined}
          onKeyDown={!disabled ? () => setOpen(!open) : undefined}
          role="button"
          appearance="simple"
          icon={displayedIcon(open ? 'down' : 'up')}
          iconPosition="right"
        >
          <input
            id={selectedOption?.value || 'no-options-selected'}
            type="button"
            className={clsx('select__input--display', inputClassName)}
            onChange={() => undefined}
            value={defaultValue()}
          />
        </Button>
        {error && <span className="select__error">{error}</span>}
        <ul className={`select__list ${dropdownStyle}`} ref={listRef}>
          {appearance === 'date' && (
            <DateRangePicker
              anchorEl={listRef}
              position={dateRangePickerPosition}
              theme={theme}
              openDatePicker={openDatePicker}
              close={() => {
                setOpenDatePicker(false);
                setOpen(false);
              }}
              setDateRange={setDateRange}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              maximumDate={maximumDate}
              minimumDate={minimumDate}
              lang={lang}
            />
          )}
          {appearance === 'date' && (
            <div className="select__date-header">
              <span className="select__date-header-subtitle">
                {isCustomSelected ? customLabel : selectedOption?.label || ''}
              </span>
              <span className="select__date-header-current">
                {dateRange ? displayRange(dateRange) : ''}
              </span>
            </div>
          )}
          {options.map((option, index) => (
            <SelectOption
              key={JSON.stringify(option.value)}
              className="select__list--option"
              option={option}
              onChange={onChange}
              setSelectedValue={setSelectedValue}
              setSelectedDate={setSelectedDate}
              setOpen={setOpen}
              index={index}
              appearance={appearance}
              theme={theme}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDateRange={setDateRange}
              setIsCustomSelected={setIsCustomSelected}
              setOpenDatePicker={setOpenDatePicker}
            />
          ))}
          {appearance === 'date' && (
            <div className="select__date-footer">
              <Button
                className="select__date-customize-btn"
                role="button"
                appearance="simple"
                onClick={() => {
                  setIsCustomSelected(true);

                  return !disabled ? setOpenDatePicker(!openDatePicker) : undefined;
                }}
                onKeyDown={!disabled ? () => setOpenDatePicker(!openDatePicker) : undefined}
              >
                {customLabel}
              </Button>
            </div>
          )}
        </ul>
      </div>
    </SelectWrapper>
  );
};

Select.defaultProps = {
  label: null,
  className: '',
  inputClassName: '',
  disabled: false,
  top: false,
  onChange: () => undefined,
  appearance: 'default',
  theme: { colors: defaultColors },
  dateRange: { start: undefined, end: undefined },
  dateRangePickerPosition: 'right',
  defaultLabel: '',
  error: '',
  lang: 'fr',
  selectedDate: '',
  setSelectedDate: () => undefined,
  isCustomSelected: false,
  setIsCustomSelected: () => undefined,
  setDateRange: () => undefined,
  maximumDate: undefined,
  minimumDate: undefined,
  customLabel: '',
  value: '',
  dropdownStyle: null,
  overrideOverflow: false,
  clearable: false,
};

Select.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({ id: PropTypes.string.isRequired }),
      ]).isRequired,
      icon: PropTypes.string,
      displayObject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  appearance: PropTypes.oneOf(['', 'default', 'lang', 'sort', 'date']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({ label: PropTypes.string.isRequired }),
  ]),
  customLabel: PropTypes.string,
  selectedDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedDate: PropTypes.func,
  isCustomSelected: PropTypes.bool,
  setIsCustomSelected: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  top: PropTypes.bool,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  dateRange: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }),
  dateRangePickerPosition: PropTypes.oneOf(['left', 'right']),
  setDateRange: PropTypes.func,
  maximumDate: PropTypes.instanceOf(Date),
  minimumDate: PropTypes.instanceOf(Date),
  defaultLabel: PropTypes.string,
  error: PropTypes.string,
  lang: PropTypes.string,
  dropdownStyle: PropTypes.string,
  overrideOverflow: PropTypes.bool,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(withTranslation('select')(Select));
