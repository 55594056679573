// eslint-disable-next-line max-classes-per-file
import '../runtime-helpers';
import React from 'react';
import App from 'next/app';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import Provider from 'components/Provider/Provider';
import { parseCookies } from 'nookies';
import colors from 'public/colors';
import { flowRight as compose } from 'lodash';
import { createClient } from 'utils/apollo';
import GlobalStyle from 'shared/global';
import NProgress from 'nprogress';
import router from 'next/router';
import { appWithTranslation, i18n } from 'i18n';
import getConfig from 'next/config';

import 'public/static/icomoon/style.css';
import 'public/static/fontstyle.css';
import 'react-nice-dates/build/style.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tailwindcss/tailwind.css';
import 'styles/globals.css';
import 'styles/tailwind.css';
import 'moment/locale/fr';
import CtrlK from '../components/CtrlK/CtrlK';
import { AuthContextProvider } from '../utils/AuthContext';

const { publicRuntimeConfig } = getConfig();
const { enableCtrlK } = publicRuntimeConfig;

router.onRouteChangeStart = () => {
  NProgress.start();
};

router.onRouteChangeComplete = () => {
  if (window) {
    window.scrollTo(0, 0);
  }

  NProgress.done();
};

router.onRouteChangeError = () => {
  NProgress.done();
};

const client = createClient();

class MyApp extends App {
  render() {
    const { language } = i18n;
    const { Component, pageProps, apollo = client } = this.props;
    const getLayout = Component.getLayout || ((page) => page);

    const { cimode } = parseCookies();
    if (cimode && language !== 'cimode') {
      i18n.changeLanguage('cimode');
    }

    return (
      <ApolloProvider client={apollo}>
        <AuthContextProvider>
          <Provider theme={{ colors }}>
            <ThemeProvider theme={{ colors }}>
              <GlobalStyle />
              {getLayout(<Component {...pageProps} />, pageProps)}
              {enableCtrlK && <CtrlK />}
            </ThemeProvider>
          </Provider>
        </AuthContextProvider>
      </ApolloProvider>
    );
  }
}

export default compose(appWithTranslation)(MyApp);
