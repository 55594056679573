// eslint-disable-next-line import/no-unresolved
import 'runtime';
import React, { useEffect, useMemo, useState } from 'react';
import { parseCookies, setCookie } from 'nookies';
import ConnectionSkeleton from 'components/ConnectionSkeleton';
import { useRouter } from 'next/router';
import Button from 'components/Button/Button';
import getConfig from 'next/config';
import { useTranslation } from 'i18n';
import Text from 'components/Text/Text';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { useSession, signOut as SsoSignOut } from 'next-auth/react';
import Loader from 'components/Loader/Loader';
import ConnectionForm from './components/ConnectionForm/ConnectionForm';
import SsoConnectionForm from './components/ConnectionForm/SsoConnectionForm';
import useSignin from '../../components/hooks/useSignin';

/** @namespace LoginImages */
const connectionSkeletonImages = {
  leftContentImage: '/static/images/banner.png',
  bgImageColor: null,
  customTagLine: null,
};

/** @namespace isOneVision @private onevision @fallback return false */
const isOneVision = true;

const { publicRuntimeConfig } = getConfig();
const { zendeskContactUrl } = publicRuntimeConfig;

const hiddenTopErrors = ['BAD_USER_INPUT', 'WRONG_CREDENTIALS', ''];

export default function Login(props) {
  const router = useRouter();
  const { t } = useTranslation('connectionform');
  const { loginMode } = parseCookies();
  const { oktatoken, oktaclientid, error: errorQuery } = router.query;
  const [useSso, setUseSso] = useState(loginMode === 'sso');
  const sessionSSO = useSession();
  const [ssoLoading, setSsoLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(errorQuery);
  const { signIn, handleSignInResponse, MultiModaleOrga } = useSignin({
    onError: (errors) => {
      if (errors.graphQLErrors) {
        errors.graphQLErrors.map(({ extensions }) => setErrorCode(extensions.code));
      }

      setError(t('error-message-connection'));
    },
  });

  const handleLoginChange = (sso) => {
    setUseSso(sso);
    setCookie({}, 'loginMode', sso ? 'sso' : 'password', {
      maxAge: Date.now() + 10 * 365 * 24 * 60 * 60,
    });
  };

  // OV Okta hook response handler
  useEffect(() => {
    if (oktatoken && oktaclientid) {
      handleSignInResponse({ clientId: oktaclientid, jwToken: oktatoken });
    }
  }, [oktatoken, oktaclientid]);

  // Others SSO hook responses handler
  useEffect(() => {
    if (sessionSSO.status === 'authenticated' && sessionSSO.data.jwToken) {
      setSsoLoading(true);
      handleSignInResponse(sessionSSO.data);
    } else if (sessionSSO.status === 'authenticated') {
      setError(t('error-no-sso'));
      SsoSignOut({ redirect: false });
    }
  }, [sessionSSO]);

  const TopErrorComponent = useMemo(
    () =>
      errorCode &&
      !hiddenTopErrors.includes(errorCode) && (
        <div className="flex flex-col justify-center gap-4">
          <Text
            size="p1"
            className="flex items-center rounded bg-red-100 p-2 text-center align-middle"
          >
            <BsExclamationTriangleFill className="w-12" /> {t(errorCode)}
          </Text>
          {(errorCode === 'USER_DISABLED' ||
            errorCode === 'SERVER_ERROR' ||
            errorCode === 'NO_LINKED_SP_LOCAL' ||
            !isOneVision) && (
            <Button
              appearance="tertiary"
              onClick={() => window.open(zendeskContactUrl, '_blank')}
              icon="icon-bell"
              iconPosition="left"
            >
              {t('error-inform-admin-btn')}
            </Button>
          )}
        </div>
      ),
    [errorCode, t],
  );

  const BottomErrorComponent = useMemo(
    () => error && <Text className="text-center text-red-500">{error}</Text>,
    [error],
  );

  const commonProps = {
    setUseSso: handleLoginChange,
    TopErrorComponent,
    BottomErrorComponent,
    signIn,
    setError,
  };

  if (ssoLoading) return <Loader type="Oval" loading />;

  return (
    <ConnectionSkeleton
      leftContentImage={connectionSkeletonImages.leftContentImage}
      bgImageColor={connectionSkeletonImages.bgImageColor}
      customTagLine={connectionSkeletonImages.customTagLine}
    >
      {useSso ? (
        <SsoConnectionForm {...props} {...commonProps} />
      ) : (
        <ConnectionForm {...props} {...commonProps} />
      )}
      {MultiModaleOrga}
    </ConnectionSkeleton>
  );
}
