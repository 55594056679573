// Path: packages/flex/src/radiogroup/radiogroup.tsx

import React from 'react';
import clsx from 'clsx';
import type { RadioGroupProperties } from './radiogroup.types';
import type { RadioProperties } from '../Radio';
import { Radio } from '../Radio';
import { Text } from '../Text';

/**
 *
 * Wraps a Radio input and provides layout utilities.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=34%3A846
 */
export const RadioGroup = ({
  label,
  className,
  type = 'simple',
  alignment = 'left',
  subtitle,
  hint,
  inputClassName,
  ...properties
}: RadioGroupProperties &
  RadioProperties &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >): JSX.Element => {
  if (type === 'card') {
    return (
      <label
        className={clsx(
          'relative flex flex-col gap-6 rounded-lg p-4 transition-all hover:cursor-pointer',
          properties.checked && 'outline outline-primary-500',
          properties.disabled
            ? 'cursor-not-allowed bg-slate-100 opacity-90'
            : 'bg-white hover:bg-slate-50',
          className,
        )}
        htmlFor={`${properties.name}-${properties.value}`}
      >
        <div className="flex w-full justify-between gap-6">
          <div className="space-y-1">
            <Text title={label} className="truncate" strong="medium">
              {label}
            </Text>
            <Text type="muted" className="truncate" title={hint}>
              {hint}
            </Text>
          </div>

          <Radio
            className={clsx('peer flex-shrink-0', inputClassName)}
            id={`${properties.name}-${properties.value}`}
            {...properties}
          />

          <div className="peer-checked:brder-solid absolute inset-0 z-10 rounded-lg border-transparent transition-colors peer-checked:border peer-checked:border-primary-500" />
        </div>

        <Text type="secondary" className="truncate" title={subtitle} size="sm">
          {subtitle}
        </Text>
      </label>
    );
  }

  if (type === 'smallCard') {
    return (
      <label
        className={clsx(
          'relative overflow-hidden rounded px-3 py-2.5 transition-all hover:cursor-pointer',
          className,
        )}
        htmlFor={`${properties.name}-${properties.value}`}
      >
        <Radio
          className={clsx('peer !sr-only flex-shrink-0', inputClassName)}
          aria-hidden="true"
          hidden
          id={`${properties.name}-${properties.value}`}
          {...properties}
        />
        <div
          className={clsx(
            'absolute inset-0 rounded border border-solid border-slate-400 transition-colors peer-checked:border-primary-500 peer-checked:bg-primary-500',
            properties.disabled
              ? 'cursor-not-allowed bg-slate-100 opacity-90'
              : 'bg-white hover:bg-slate-50',
          )}
        />
        <Text title={label} className="invisible truncate" strong="medium">
          {label}
        </Text>
        <Text
          title={label}
          className={clsx(
            'absolute inset-0 flex items-center justify-center truncate transition-colors peer-checked:text-white',
            properties.checked && '!text-white',
          )}
          strong="medium"
        >
          {label}
        </Text>
      </label>
    );
  }

  return (
    <label
      className={clsx(
        'flex space-x-1 hover:cursor-pointer',
        { left: 'flex-row', right: 'flex-row-reverse' }[alignment],
        className,
      )}
      htmlFor={`${properties.name}-${properties.value}`}
    >
      <Radio
        className={clsx('flex-shrink-0', inputClassName)}
        id={`${properties.name}-${properties.value}`}
        {...properties}
      />
      <div className="w-max">
        <Text className="truncate" title={label} strong="medium">
          {label}
        </Text>
        <Text type="muted" className="truncate" title={hint}>
          {hint}
        </Text>
      </div>
    </label>
  );
};
