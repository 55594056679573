export enum ContentType {
  TextOnly = 'TextOnly',
  ButtonRedirect = 'ButtonRedirect',
}

export type SubActions = {
  [key: string]: string | number;
};

export type SubContent = {
  id: string;
  [key: string]: string | number | SubActions;
};

export type DataRow = {
  id: string;
  [key: string]: string | number | SubContent[] | undefined;
};

export type FooterProps = {
  count: number;
  index: number;
  pageChange: (page: number) => void;
};

export type Sort = {
  criteria: keyof DataRow;
  direction: SortDirection;
};



export enum SortDirection {
  ASC = 1,
  DESC = -1,
}

export type Column<T> = {
  key: keyof T; // La clé dans les objets de `data`
  name: string | (() => React.ReactNode); // Nom de la colonne
  renderCell?: (item: T) => React.ReactNode; // Fonction pour personnaliser une cellule
  useForSort?: boolean; // Utilisé pour afficher le tri
  responsiveWitdh?: string; // Largeur de la colonne
};

export type SortField<T> = {
  field: keyof T; // Clé utilisée pour le tri, basée sur les clés de l'objet de données T
  typeOfSort: SortDirection; // Direction du tri, basée sur l'énumération
};

export type TableProps<T> = {
  columns: Column<T>[]; // Définition des colonnes
  data: T[]; // Données à afficher
  onRowClick?: (item: T) => void; // Interaction sur une ligne
  onHeaderSortClick?: (sortField: SortField<T>) => void; // Interaction sur un tri
  sortField?: SortField<T>; // Champ utilisé pour le tri
};
