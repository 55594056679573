import { useEffect } from 'react';
import { i18n } from 'i18n';

export const config = [
  { value: 'fr', id: 'fr', label: 'Français', icon: '', zenDesk: 'fr' },
  { value: 'en', id: 'en', label: 'English', icon: '', zenDesk: 'en-US' },
  { value: 'es', id: 'es', label: 'Español', icon: '', zenDesk: 'es' },
  // To activate when the translations are proof read by a human
  // { value: 'it', id: 'it', label: 'Italiano', icon: '', zenDesk: 'en-US' },
  { value: 'nl', id: 'nl', label: 'Nederlands', icon: '', zenDesk: 'en-US' },
  { value: 'de', id: 'de', label: 'Deutsch', icon: '', zenDesk: 'en-US' },
  // { value: 'ar', id: 'ar', label: 'Arabe', icon: '/static/images/ar.png', zenDesk: 'en-US' },
];

export default function useLanguage() {
  const { language } = i18n;

  function setLanguage(_language) {
    if (!_language) return Promise.resolve();
    if (!config.some((l) => l.id === _language)) return Promise.resolve();

    return i18n.changeLanguage(_language.toLowerCase());
  }

  useEffect(() => {
    if (language !== 'cimode') {
      window?.zE?.('webWidget', 'setLocale', config.find((lg) => lg.value === language).zenDesk);
    }
  }, [language]);

  return { language, setLanguage, availableLanguages: config };
}
