import { SalesPoint, User } from '@schema';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface AuthContextType {
  contextUser: User | null;
  salespointContext: SalesPoint | null;
  setContextUser: React.Dispatch<React.SetStateAction<User | null>>;
  setSalespointContext: React.Dispatch<React.SetStateAction<SalesPoint | null>>;
}

// TODO check when administrator changes user store while still connected
// TODO setAuthContext to be checked with previous clientId connected (as superadmin)
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [contextUser, setContextUser] = useState<User | null>(null);
  const [salespointContext, setSalespointContext] = useState<SalesPoint | null>(null);

  const [isMounted, setIsMounted] = useState(false); // Indicate if the component is mounted

  useEffect(() => {
    setIsMounted(true);

    if (typeof window !== 'undefined') {
      const storedUser = sessionStorage.getItem('contextUser');
      setContextUser(storedUser ? JSON.parse(storedUser) : null);
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (contextUser) {
        if (typeof window !== 'undefined') {
          sessionStorage.setItem('contextUser', JSON.stringify(contextUser));
        }
      } else if (typeof window !== 'undefined') {
        sessionStorage.removeItem('contextUser');
      }
    }
  }, [contextUser, isMounted]);

  if (!isMounted) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ contextUser, setContextUser, salespointContext, setSalespointContext }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthContextProvider');
  }

  return context;
};
